<script>
    import { toast } from "@zerodevx/svelte-toast";
    import { AccordionItem, Button, Spinner } from "flowbite-svelte";
    import {
        AdjustmentsHorizontalOutline,
        FileCopyOutline,
        ExclamationCircleOutline,
    } from "flowbite-svelte-icons";
    import { marked } from "marked";
    import { askAssistant } from "../service/http_calls";
    import {
        ajust_response_modal_open,
        current_user,
        fact_checks_modal_open,
        general_prompt,
        question_prompt_edit_open,
        questions,
        selected_question,
        source_sentence_questions_modal_open,
        sources,
    } from "../service/store";

    export let question;
    export let title;
    export let hideAdjustment = false;
    let loading = false;
    async function handleCompile(question) {
        questions.update((prev) => {
            prev[question].response.status = "processing";
            return prev;
        });
        loading = true;
        const depenency_request_ids = $questions[question].dependencies
            .map((dependency) => $questions[dependency].request_id)
            .filter((id) => id !== undefined && id !== "");
        let resp = await askAssistant(
            $general_prompt,
            $questions[question].prompt,
            question,
            "draft",
            depenency_request_ids,
        );
        if (resp.status == "rejected") {
            toast.push(resp.reason);
        } else {
            toast.push("Request submitted");
        }

        loading = false;
    }
    function makeReferencesClickable(content) {
        return content.replace(
            /\[(\d+)\]/g,
            '<a href="#" data-ref="$1">[$1]</a>',
        );
    }

    $: formattedContent = makeReferencesClickable(
        $questions[question]?.response?.content || "",
    );
    let status;
    $: status = $questions[question]?.response?.status;

    // Event handler for clicks on reference links
    function handleReferenceClick(event) {
        const target = event.target;
        if (target.tagName === "A" && target.dataset.ref) {
            event.preventDefault();
            const refNumber = target.dataset.ref;
            console.log(`Reference [${refNumber}] clicked`);
            // You can handle the click here, e.g., scroll to a reference, open a modal, etc.
            source_sentence_questions_modal_open.set(true);
            sources.set($questions[question].response.sources[refNumber]);
        }
    }
</script>

<AccordionItem>
    <span slot="header">{title}</span>
    <div
        role="button"
        tabindex="0"
        class="prose max-w-full"
        on:click={(event) => {
            
            // toast.push("Fetching sources...");
            if (
                event.target.tagName.toLowerCase() === "p" ||
                event.target.tagName.toLowerCase() === "ul"
            ) {
                const paragraphContent = event.target.innerText;
                console.log(paragraphContent);
            }

            if (event.target.tagName === "A" && event.target.dataset.ref) {
                event.preventDefault();
                const refNumber = event.target.dataset.ref;
                console.log(`Reference [${refNumber}] clicked`);
                // You can handle the click here, e.g., scroll to a reference, open a modal, etc.
                source_sentence_questions_modal_open.set(true);
                sources.set($questions[question].response.sources[refNumber]);
            }
        }}
        on:keydown={(event) => {
            console.log(event);
        }}
    >
        {#if formattedContent}
            {@html marked(formattedContent)}
        {:else}
            <p>No content generated yet</p>
        {/if}
    </div>
    <div class="flex justify-between mt-5">
        <div class="flex justify-start">
            <button
                on:click={() => {
                    let cleanedText = $questions[
                        question
                    ]?.response.content.replace(/\[\d+\]/g, "");
                    navigator.clipboard.writeText(cleanedText);
                    toast.push("Copied to clipboard!");
                }}
                style="background: none; border: none; cursor: pointer;"
                class="mr-5"
            >
                <FileCopyOutline></FileCopyOutline>
            </button>
        </div>

        <div class="flex justify-end">
            {#if !hideAdjustment && $current_user?.user_type === "admin"}
                <button
                    on:click={() => {
                        ajust_response_modal_open.set(true);
                    }}
                    style="background: none; border: none; cursor: pointer;"
                    class="mr-5"
                >
                    <AdjustmentsHorizontalOutline
                    ></AdjustmentsHorizontalOutline>
                </button>
            {/if}

            <!-- {#if status === "done" || typeof status === "undefined"} -->

            {#if $current_user?.user_type === "admin"}
                {#if $questions[question]?.response?.fact_checks && $questions[question]?.response?.fact_checks?.trim() !== ""}
                    <Button
                        size="xs"
                        color="alternative"
                        class="mr-5"
                        on:click={() => {
                            selected_question.set(question);
                            fact_checks_modal_open.set(true);
                        }}
                    >
                        <ExclamationCircleOutline
                            class="w-4 h-4 me-2 text-red-700"
                        />
                        Show Fact Checks
                    </Button>
                {/if}
            {/if}

            <Button
                size="xs"
                color="alternative"
                class="mr-5"
                on:click={() => {
                    source_sentence_questions_modal_open.set(true);
                    sources.set(
                        $questions[question].response.chunks.join(
                            "\n\n---\n\n",
                        ),
                    );
                }}>Show All Sources</Button
            >
            <Button
                size="xs"
                color="alternative"
                class="mr-5"
                on:click={() => {
                    question_prompt_edit_open.set(true);
                    selected_question.set(question);
                }}>Add Instructions</Button
            >
            {#if loading}
                <Spinner></Spinner>
            {:else}
                <Button
                    size="xs"
                    color="purple"
                    on:click={() => {
                        handleCompile(question);
                    }}>Regenerate</Button
                >
            {/if}
            {#if status !== "done" && typeof status !== "undefined"}
                <div class="ml-5 flex flex-row mt-1">
                    <small>Status: <b>{status}</b></small>
                    <Spinner size={5} class="ml-3"></Spinner>
                </div>
            {/if}
        </div>
    </div>
</AccordionItem>
