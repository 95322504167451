<script>
    import {
        Accordion,
        AccordionItem,
        Button,
        Card,
        P,
        Span,
        Spinner,
        Textarea,
        Modal,
        Dropdown,
        DropdownItem,
    } from "flowbite-svelte";
    import { ChevronDownOutline } from "flowbite-svelte-icons";
    import { onMount } from "svelte";
    import { ask_llm, fetchFiles, download_file } from "../service/http_calls";
    import { files_list, review, review_inital } from "../service/store.js";
    import FilesTable from "./FilesTable.svelte";
    import { marked } from "marked";
    import { toast } from "@zerodevx/svelte-toast";

    onMount(async () => {
        const resp = await fetchFiles("review");

        files_list.update((prev) => {
            prev["review"] = resp;
            return prev;
        });
    });

    let loading_review = false;
    let clearResponsesModal = false;
    let downloading_file = false;

    const submitReview = async () => {
        loading_review = true;

        console.log("submitReview");
        // handling coherence
        const resp_coherence = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "overall",
            "coherence",
            "gpt-4o",
        );
        review.update((prev) => {
            prev["overall"]["coherence"]["response"] =
                resp_coherence.generated_content;
            return prev;
        });
        toast.push("Coherence Done");
        // handling risk factors
        const resp_risk_factors = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "overall",
            "risk_factors",
            "gpt-4o",
        );
        review.update((prev) => {
            prev["overall"]["risk_factors"]["response"] =
                resp_risk_factors.generated_content;
            return prev;
        });
        toast.push("Risk Factors Done");

        // handling research - baseline
        const resp_research = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "research",
            "upload",
            "baseline",
            "gpt-4o",
        );
        review.update((prev) => {
            prev["research"]["baseline"]["response"] =
                resp_research.generated_content;
            return prev;
        });
        toast.push("Research Done");
        // handling research - internet
        const resp_research_internet = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution +
                $review.research.baseline.response,
            "research",
            "upload",
            "internet",
            "gpt-4o",
        );
        review.update((prev) => {
            prev["research"]["internet"]["response"] =
                resp_research_internet.generated_content;
            return prev;
        });
        toast.push("Research Internet Done");
        // handling research - baseline research
        const resp_research_baseline = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution +
                $review.research.baseline.response +
                $review.research.internet.response,
            "review",
            "overall",
            "baseline_research",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["research"]["baseline_research"]["response"] =
                resp_research_baseline.generated_content;
            return prev;
        });
        toast.push("Research Baseline Done");

        // handle baseline
        //handle comprehensiveness
        const resp_comprehensiveness = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "baseline",
            "comprehensiveness",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["baseline"]["comprehensiveness"]["response"] =
                resp_comprehensiveness.generated_content;
            return prev;
        });
        toast.push("Comprehensiveness Done");
        // handle focus
        const resp_focus = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "baseline",
            "focus",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["baseline"]["focus"]["response"] =
                resp_focus.generated_content;
            return prev;
        });
        toast.push("Focus Done");
        // handle phrasing
        const resp_phrasing = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "baseline",
            "phrasing",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["baseline"]["phrasing"]["response"] =
                resp_phrasing.generated_content;
            return prev;
        });
        toast.push("Phrasing Done");
        // handle grammar
        const resp_grammar = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "baseline",
            "grammar",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["baseline"]["grammar"]["response"] =
                resp_grammar.generated_content;
            return prev;
        });
        toast.push("Grammar Done");

        // handle advance
        // handle comprehensiveness
        const resp_advance_comprehensiveness = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "advance",
            "comprehensiveness",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["advance"]["comprehensiveness"]["response"] =
                resp_advance_comprehensiveness.generated_content;
            return prev;
        });
        toast.push("Advance Comprehensiveness Done");
        // handle focus
        const resp_advance_focus = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution ,
            "review",
            "advance",
            "focus",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["advance"]["focus"]["response"] =
                resp_advance_focus.generated_content;
            return prev;
        });
        toast.push("Advance Focus Done");
        // handle phrasing
        const resp_advance_phrasing = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution ,
            "review",
            "advance",
            "phrasing",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["advance"]["phrasing"]["response"] =
                resp_advance_phrasing.generated_content;
            return prev;
        });
        toast.push("Advance Phrasing Done");
        // handle grammar
        const resp_advance_grammar = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution ,

            "review",
            "advance",
            "grammar",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["advance"]["grammar"]["response"] =
                resp_advance_grammar.generated_content;
            return prev;
        });
        toast.push("Advance Grammar Done");


        // handle uncertainty
        // handle comprehensiveness
        const resp_uncertainty_comprehensiveness = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "uncertainty",
            "comprehensiveness",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["uncertainty"]["comprehensiveness"]["response"] =
                resp_uncertainty_comprehensiveness.generated_content;
            return prev;
        });
        toast.push("Uncertainty Comprehensiveness Done");
        // handle focus
        const resp_uncertainty_focus = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "uncertainty",
            "focus",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["uncertainty"]["focus"]["response"] =
                resp_uncertainty_focus.generated_content;
            return prev;
        });
        toast.push("Uncertainty Focus Done");
        // handle phrasing
        const resp_uncertainty_phrasing = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "uncertainty",
            "phrasing",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["uncertainty"]["phrasing"]["response"] =
                resp_uncertainty_phrasing.generated_content;
            return prev;    
        });
        toast.push("Uncertainty Phrasing Done");
        // handle grammar
        const resp_uncertainty_grammar = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "uncertainty",
            "grammar",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["uncertainty"]["grammar"]["response"] =
                resp_uncertainty_grammar.generated_content;
            return prev;
        });
        toast.push("Uncertainty Grammar Done");

        // handle resolution
        // handle comprehensiveness
        const resp_resolution_comprehensiveness = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "resolution",
            "comprehensiveness",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["resolution"]["comprehensiveness"]["response"] =
                resp_resolution_comprehensiveness.generated_content;
            return prev;    
        });
        toast.push("Resolution Comprehensiveness Done");
        // handle focus
        const resp_resolution_focus = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "resolution",
            "focus",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["resolution"]["focus"]["response"] =
                resp_resolution_focus.generated_content;
            return prev;
        });
        toast.push("Resolution Focus Done");
        // handle phrasing
        const resp_resolution_phrasing = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "resolution",
            "phrasing",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["resolution"]["phrasing"]["response"] =
                resp_resolution_phrasing.generated_content;
            return prev;
        });
        toast.push("Resolution Phrasing Done");
        // handle grammar
        const resp_resolution_grammar = await ask_llm(
            $review.user_instructions.baseline +
                "\n" +
                $review.user_instructions.advance +
                "\n" +
                $review.user_instructions.uncertainty +
                "\n" +
                $review.user_instructions.resolution,
            "review",
            "resolution",
            "grammar",
            "gemini-1.5-flash",
        );
        review.update((prev) => {
            prev["resolution"]["grammar"]["response"] =
                resp_resolution_grammar.generated_content;
            return prev;
        }); 
        toast.push("Resolution Grammar Done");

        loading_review = false;
    };
    async function handleDownload(doc_type) {
        downloading_file = true;
        let text = "";

        // Overall section
        if ($review.overall.coherence.response) {
            text += `**Coherence**\n\n${$review.overall.coherence.response}\n<hr/>\n\n`;
        }
        if ($review.overall.risk_factors.response) {
            text += `**Risk Factors**\n\n${$review.overall.risk_factors.response}\n<hr/>\n\n`;
        }

        // Research section
        if ($review.research.baseline.response) {
            text += `**Baseline**\n\n${$review.research.baseline.response}\n<hr/>\n\n`;
        }
        if ($review.research.internet.response) {
            text += `**Internet**\n\n${$review.research.internet.response}\n<hr/>\n\n`;
        }
        if ($review.research.baseline_research.response) {
            text += `**Baseline Research**\n\n${$review.research.baseline_research.response}\n<hr/>\n\n`;
        }

        await download_file(text, doc_type, "review");
        downloading_file = false;
    }
    $: submit_enabled =
        $review.user_instructions.baseline &&
        $review.user_instructions.advance &&
        $review.user_instructions.uncertainty &&
        $review.user_instructions.resolution;
</script>

<div class="w-full flex flex-grow flex-col justify-center mb-5">
    <div class="justify-center flex mt-5">
        <Card size="xl" class="mb-20">
            <P size="lg" class="my-5"><Span gradient>Baseline</Span></P>
            <Textarea
                bind:value={$review.user_instructions.baseline}
                rows={10}
                placeholder="Enter Technical Narrative Draft Here..."
            ></Textarea>
            <P size="lg" class="my-5"><Span gradient>Advance</Span></P>
            <Textarea
                bind:value={$review.user_instructions.advance}
                rows={10}
                placeholder=""
            ></Textarea>
            <P size="lg" class="my-5"><Span gradient>Uncertainty</Span></P>
            <Textarea
                bind:value={$review.user_instructions.uncertainty}
                rows={10}
                placeholder=""
            ></Textarea>
            <P size="lg" class="my-5"><Span gradient>Resolution</Span></P>
            <Textarea
                bind:value={$review.user_instructions.resolution}
                rows={10}
                placeholder=""
            ></Textarea>
            <div class="flex justify-between items-center mt-5">
                <div class="flex-grow"></div>
                {#if loading_review}
                    <div class="flex items-center mx-5">
                        <Spinner class="mr-2"></Spinner>
                        <P>Submitting Review...</P>
                    </div>
                {:else}
                    <Button
                        color="purple"
                        on:click={() => submitReview()}
                        disabled={!submit_enabled}>Submit</Button
                    >
                {/if}
            </div>

            <P size="lg" class="my-5"><Span gradient>Overall</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Coherence
                    </span>
                    <div class="prose prose-sm">
                        {#if $review.overall.coherence.response}
                            {@html marked($review.overall.coherence.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Risk factors (if applicable)</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.overall.risk_factors.response}
                            {@html marked(
                                $review.overall.risk_factors.response,
                            )}
                        {/if}
                    </div>
                </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Research</Span></P>

            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Baseline Statement</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.research.baseline.response}
                            {@html marked($review.research.baseline.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Interenet Research</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.research.internet.response}
                            {@html marked($review.research.internet.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Baseline Research</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.research.baseline_research.response}
                            {@html marked(
                                $review.research.baseline_research.response,
                            )}
                        {/if}
                    </div>
                </AccordionItem>
            </Accordion>
            <P size="lg" class="my-5"><Span gradient>Baseline</Span></P>
            <!-- add accordiosn for comprehensiveness, focus, phrasing, grammar here -->
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Comprehensiveness</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.baseline.comprehensiveness.response}
                            {@html marked($review.baseline.comprehensiveness.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Focus</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.baseline.focus.response}
                            {@html marked($review.baseline.focus.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Phrasing</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.baseline.phrasing.response}
                            {@html marked($review.baseline.phrasing.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Grammar</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.baseline.grammar.response}
                            {@html marked($review.baseline.grammar.response)}
                        {/if}
                    </div>
                </AccordionItem>
            </Accordion>


            <P size="lg" class="my-5"><Span gradient>Advance</Span></P>
            <!-- add accordiosn for comprehensiveness, focus, phrasing, grammar here -->
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Comprehensiveness</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.advance.comprehensiveness.response}
                            {@html marked($review.advance.comprehensiveness.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Focus</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.advance.focus.response}
                            {@html marked($review.advance.focus.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Phrasing</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.advance.phrasing.response}
                            {@html marked($review.advance.phrasing.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Grammar</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.advance.grammar.response}
                            {@html marked($review.advance.grammar.response)}
                        {/if}
                    </div>
                    </AccordionItem>
            </Accordion>

            <P size="lg" class="my-5"><Span gradient>Uncertainty</Span></P>
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Comprehensiveness</span>   
                    <div class="prose prose-sm">
                        {#if $review.uncertainty.comprehensiveness.response}
                            {@html marked($review.uncertainty.comprehensiveness.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Focus</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.uncertainty.focus.response}
                            {@html marked($review.uncertainty.focus.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Phrasing</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.uncertainty.phrasing.response}
                            {@html marked($review.uncertainty.phrasing.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Grammar</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.uncertainty.grammar.response}
                            {@html marked($review.uncertainty.grammar.response)}
                        {/if}
                    </div>
                </AccordionItem>
            </Accordion>    
            <P size="lg" class="my-5"><Span gradient>Resolution</Span></P>
            <Accordion
                multiple
                activeClass="bg-blue-100 dark:bg-gray-800 text-blue-600 dark:text-white focus:ring-4 focus:ring-blue-200 dark:focus:ring-blue-800"
                inactiveClass="text-gray-500 dark:text-gray-400 hover:bg-blue-100 dark:hover:bg-gray-800"
            >
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Comprehensiveness</span
                    >   
                    <div class="prose prose-sm">
                        {#if $review.resolution.comprehensiveness.response}
                            {@html marked($review.resolution.comprehensiveness.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Focus</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.resolution.focus.response}
                            {@html marked($review.resolution.focus.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Phrasing</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.resolution.phrasing.response}
                            {@html marked($review.resolution.phrasing.response)}
                        {/if}
                    </div>
                </AccordionItem>
                <AccordionItem>
                    <span slot="header" class="inline-flex items-center mr-10"
                        >Grammar</span
                    >
                    <div class="prose prose-sm">
                        {#if $review.resolution.grammar.response}
                            {@html marked($review.resolution.grammar.response)}
                        {/if}
                    </div>
                </AccordionItem>
            </Accordion>    


            <div class="flex justify-end mt-10">
                <Button
                    color="red"
                    class="mr-5"
                    on:click={() => {
                        clearResponsesModal = true;
                    }}>Clear All</Button
                >
                {#if downloading_file}
                    <div class="flex justify-center">
                        <Spinner></Spinner>
                    </div>
                {:else}
                    <Button color="purple"
                        >Download<ChevronDownOutline
                            class="w-6 h-6 ms-2 text-white dark:text-white"
                        /></Button
                    >
                    <Dropdown>
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("docx");
                            }}>Docx</DropdownItem
                        >
                        <DropdownItem
                            on:click={async () => {
                                await handleDownload("pdf");
                            }}>PDF</DropdownItem
                        >
                        <DropdownItem
                            on:click={() => {
                                let text = "";
                                if ($review.overall.coherence.response) {
                                    text += `Coherence\n${$review.overall.coherence.response}\n\n`;
                                }
                                if ($review.overall.risk_factors.response) {
                                    text += `Risk Factors\n${$review.overall.risk_factors.response}\n\n`;
                                }
                                if ($review.research.baseline.response) {
                                    text += `Baseline\n${$review.research.baseline.response}\n\n`;
                                }
                                if ($review.research.internet.response) {
                                    text += `Internet\n${$review.research.internet.response}\n\n`;
                                }
                                if (
                                    $review.research.baseline_research.response
                                ) {
                                    text += `Baseline Research\n${$review.research.baseline_research.response}\n\n`;
                                }
                                text = text.trim();
                                toast.push("Copied to clipboard");
                                navigator.clipboard.writeText(text);
                            }}>Clipboard</DropdownItem
                        >
                    </Dropdown>
                {/if}
            </div>
            <div class="flex justify-center my-10">
                <P size="xs" class="text-gray-800"
                    >While AI systems strive for accuracy, occasional errors may
                    occur. We recommend reviewing the output carefully.</P
                >
            </div>
        </Card>
    </div>
</div>
<Modal
    title="Clear Generated Responses"
    bind:open={clearResponsesModal}
    autoclose
>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        Are you sure you want to clear all the generated responses for the
        questions?
    </p>
    <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
        This action cannot be undone. All generated content will be removed.
    </p>
    <svelte:fragment slot="footer">
        <Button
            color="red"
            on:click={() => {
                console.log("clearing responses");
                review.update((prev) => {
                    return review_inital;
                });
                toast.push("Responses cleared");
            }}>Clear Responses</Button
        >
        <Button
            color="alternative"
            on:click={() => (clearResponsesModal = false)}>Cancel</Button
        >
    </svelte:fragment>
</Modal>
